// extracted by mini-css-extract-plugin
export const root = "PlasmicHomepage-module--root--Ri2Wc";
export const box__c0J0 = "PlasmicHomepage-module--box__c0J0--378yN";
export const box__s7DPj = "PlasmicHomepage-module--box__s7DPj--3X3Ic";
export const box__jDt1P = "PlasmicHomepage-module--box__jDt1P--1X0K7";
export const link__rs8O1 = "PlasmicHomepage-module--link__rs8O1--3hdif";
export const link__tMgGq = "PlasmicHomepage-module--link__tMgGq--3ggpO";
export const link__k7Sr = "PlasmicHomepage-module--link__k7Sr--1isat";
export const button1 = "PlasmicHomepage-module--button1--38LiI";
export const button__fv9WT = "PlasmicHomepage-module--button__fv9WT--3zVzs";
export const button__w9Bcl = "PlasmicHomepage-module--button__w9Bcl--1KkrZ";
export const box__rDuq4 = "PlasmicHomepage-module--box__rDuq4--1GqcM";
export const box__swDn3 = "PlasmicHomepage-module--box__swDn3--37Cr3";
export const box___3KcwS = "PlasmicHomepage-module--box___3KcwS--3nJ1j";
export const box__rUrpi = "PlasmicHomepage-module--box__rUrpi--huIMS";
export const box__jUsAo = "PlasmicHomepage-module--box__jUsAo--3a4UR";
export const box___3TgP3 = "PlasmicHomepage-module--box___3TgP3--i4JFn";
export const box__r5Zzg = "PlasmicHomepage-module--box__r5Zzg--XX2wY";
export const box__eaTt = "PlasmicHomepage-module--box__eaTt--23GAW";
export const box__oNb8Q = "PlasmicHomepage-module--box__oNb8Q--2B4m-";
export const box__wf29M = "PlasmicHomepage-module--box__wf29M--1zLpx";
export const box__ivjvZ = "PlasmicHomepage-module--box__ivjvZ--1MmRN";
export const box__tgQ2J = "PlasmicHomepage-module--box__tgQ2J--6ZQ4b";
export const box__dkKp1 = "PlasmicHomepage-module--box__dkKp1--3IWOc";
export const box__brdvy = "PlasmicHomepage-module--box__brdvy--fuc3I";
export const box___73M5S = "PlasmicHomepage-module--box___73M5S--233_e";
export const box__hD6Hu = "PlasmicHomepage-module--box__hD6Hu--1TfTq";
export const box__skpEg = "PlasmicHomepage-module--box__skpEg--2bxfX";